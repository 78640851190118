<template>
  <div>
    <div v-if="loading" class="clearfix">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <div v-else>
      <div>
        <b-card title="Simulação de emprestimos">
          <validation-observer ref="form">
            <b-form @submit.prevent>
              <b-row>
                <b-col cols="12" md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="registro"
                    rules="required"
                  >
                    <b-form-group label="Registro" label-for="v-registro">
                      <v-select
                        v-model="form.registro"
                        label="nome"
                        :filterable="false"
                        :options="comboRegistros"
                        :selected="form.registro"
                        :disabled="!!$route.params.id"
                        @search="onSearch"
                        ><template slot="no-options">
                          Pesquisa registro por nome, cpf ou matrícula
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.nome }}
                          </div>
                        </template>
                      </v-select>
                      <small data-cy="v-registro" class="text-danger">{{
                        errors[0]
                      }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- first name -->
                <b-col cols="12" md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="numero"
                    rules=""
                  >
                    <b-form-group label="Número" label-for="v-numero">
                      <b-form-input
                        id="v-numero"
                        v-model="form.numero"
                        :state="errors.length > 0 ? false : null"
                        placeholder="numero"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- first name -->
                <b-col cols="12">
                  <validation-provider
                    #default="{ errors }"
                    name="descrição"
                    rules=""
                  >
                    <b-form-group label="Descrição" label-for="v-descricao">
                      <b-form-input
                        id="v-descricao"
                        v-model="form.descricao"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Descrição"
                        :disabled="!!$route.params.id"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- first valor -->
                <b-col cols="12" sm="12" md="6" lg="6">
                  <validation-provider
                    #default="{ errors }"
                    name="valor"
                    rules="required"
                  >
                    <b-form-group label="Valor" label-for="v-Valor">
                      <cleave
                        id="v-valor"
                        v-model.number="form.valor"
                        class="form-control"
                        :raw="true"
                        :options="options.number"
                        placeholder="Valor"
                        :class="[errors.length > 0 ? isValid : '']"
                        :disabled="!!$route.params.id"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- first valor -->
                <b-col cols="12" sm="12" md="6" lg="6">
                  <validation-provider
                    #default="{ errors }"
                    name="valor total"
                    rules=""
                  >
                    <b-form-group label="Valor Total" label-for="v-valorTotal">
                      <cleave
                        id="v-valorTotal"
                        v-model.number="form.valorTotal"
                        class="form-control"
                        :raw="true"
                        :options="options.number"
                        placeholder="valor total"
                        disabled
                        :class="[errors.length > 0 ? isValid : '']"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- first qtd parcelas -->
                <b-col cols="12" md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Quantidade Parcelas"
                    rules="required"
                  >
                    <b-form-group
                      label="Quantidade Parcelas"
                      label-for="v-qtoParcelas"
                    >
                      <b-form-input
                        id="v-qtoParcelas"
                        v-model="form.qtoParcelas"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Quantidade Parcelas"
                        type="number"
                        :disabled="!!$route.params.id"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!--  TAXA -->
                <b-col cols="12" sm="12" md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Taxas"
                    rules="required"
                  >
                    <b-form-group label="Taxas" label-for="v-taxa">
                      <v-select
                        v-model="form.taxa"
                        :reduce="(comboTaxas) => comboTaxas.id"
                        label="nome"
                        :options="comboTaxas"
                        :state="errors.length > 0 ? false : null"
                        :class="[errors.length > 0 ? isValid : '']"
                        :disabled="!!$route.params.id"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!--  TIPO -->
                <b-col cols="12" lg="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Tipo de Empréstimo"
                    rules="required"
                  >
                    <b-form-group
                      label="Tipo de Empréstimo"
                      label-for="v-tipoPagamento"
                    >
                      <v-select
                        v-model="form.tipo"
                        :reduce="
                          (tipoFiltradoPorEmprestimos) =>
                            tipoFiltradoPorEmprestimos.id
                        "
                        label="nome"
                        :options="tipoFiltradoPorEmprestimos"
                        :state="errors.length > 0 ? false : null"
                        :class="[errors.length > 0 ? isValid : '']"
                        :disabled="!!$route.params.id"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!--  CONTA -->
                <b-col cols="12" sm="12" md="12" lg="6">
                  <validation-provider
                    #default="{ errors }"
                    name="conta"
                    rules="required"
                  >
                    <b-form-group label="Conta" label-for="v-conta">
                      <v-select
                        v-model="form.conta"
                        :reduce="(comboContas) => comboContas.id"
                        label="descricao"
                        :options="comboContas"
                        :state="errors.length > 0 ? false : null"
                        :class="[errors.length > 0 ? isValid : '']"
                        :disabled="!!$route.params.id"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!--  DATA LANCAMENTO -->
                <b-col cols="12" lg="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Data Lançamento"
                    rules="required"
                  >
                    <b-form-group
                      label="Data Lançamento"
                      label-for="v-dataLancamento"
                    >
                      <flat-pickr
                        v-model="form.dataLancamento"
                        name="fieldDate"
                        class="form-control"
                        :disabled="!!$route.params.id"
                        :config="{
                          altInput: true,
                          altFormat: 'd/m/Y',
                          enableTime: false,
                          dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                        }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col v-if="!!form.itens" cols="12">
                  <b-card title="Parcelas">
                    <vue-good-table
                      :columns="columns"
                      :rows="form.itens"
                      :rtl="direction"
                      :pagination-options="{
                        enabled: true,
                        perPage: pageLength
                      }"
                    >
                      <template slot="table-row" slot-scope="props">
                        <!-- Column: Action -->
                        <span v-if="props.column.field === 'action'">
                          <span>
                            <b-dropdown
                              variant="link"
                              toggle-class="text-decoration-none"
                              no-caret
                            >
                              <template v-slot:button-content>
                                <feather-icon
                                  icon="MoreVerticalIcon"
                                  size="16"
                                  class="text-body align-middle mr-25"
                                />
                              </template>
                              <b-dropdown-item @click="editParcela(props.row)">
                                <feather-icon icon="Edit2Icon" class="mr-50" />
                                <span>Edit</span>
                              </b-dropdown-item>
                              <b-dropdown-item
                                @click="deleteParcela(props.row)"
                              >
                                <feather-icon icon="TrashIcon" class="mr-50" />
                                <span>Delete</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </span>
                        </span>

                        <!-- Column: Common -->
                        <span v-else>
                          {{ props.formattedRow[props.column.field] }}
                        </span>
                      </template>

                      <!-- pagination -->
                      <template slot="pagination-bottom" slot-scope="props">
                        <div class="d-flex justify-content-between flex-wrap">
                          <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap"> Mostrando 1 a </span>
                            <b-form-select
                              v-model="pageLength"
                              :options="['3', '5', '10']"
                              class="mx-1"
                              @input="
                                (value) =>
                                  props.perPageChanged({
                                    currentPerPage: value
                                  })
                              "
                            />
                            <span class="text-nowrap">
                              de {{ props.total }} registros
                            </span>
                          </div>
                          <div>
                            <b-pagination
                              :value="1"
                              :total-rows="props.total"
                              :per-page="pageLength"
                              first-number
                              last-number
                              align="right"
                              prev-class="prev-item"
                              next-class="next-item"
                              class="mt-1 mb-0"
                              @input="
                                (value) =>
                                  props.pageChanged({ currentPage: value })
                              "
                            >
                              <template #prev-text>
                                <feather-icon
                                  icon="ChevronLeftIcon"
                                  size="18"
                                />
                              </template>
                              <template #next-text>
                                <feather-icon
                                  icon="ChevronRightIcon"
                                  size="18"
                                />
                              </template>
                            </b-pagination>
                          </div>
                        </div>
                      </template>
                    </vue-good-table>
                  </b-card>
                </b-col>

                <b-col cols="12">
                  <b-button
                    v-if="!$route.params.id"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1 mt-1"
                    @click="save"
                  >
                    Salvar
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    class="mt-1"
                    variant="outline-secondary"
                    @click="cancelar"
                  >
                    Cancelar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  BRow,
  BCol,
  BSpinner,
  BCard,
  BListGroup,
  BListGroupItem,
  BBadge,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormSelect,
  BInputGroup
} from 'bootstrap-vue'
import FormPaymentReceiptAccount from '@/layouts/components/FormPaymentReceiptAccount.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'
import mixinsGlobal from '@/mixins'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import TableComponents from '@/layouts/components/TableComponents.vue'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'

export default {
  name: 'FormEmprestimo',
  components: {
    FormPaymentReceiptAccount,
    BSpinner,
    BCard,
    BListGroup,
    BListGroupItem,
    BBadge,
    BButton,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    Cleave,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormSelect,
    BInputGroup,
    TableComponents,
    flatPickr
  },
  directives: {
    Ripple
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('combosModule', {
      comboTaxas: (state) => state.comboTaxas,
      comboTipoPagamento: (state) => state.comboTipoPagamento,
      comboContas: (state) => state.comboContas
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  beforeMount() {
    this.carregarDados()
  },
  async mounted() {
    this.loading = false
    if (this.$route.params.id) {
      const data = await this.$store.dispatch(
        'parcelamentoFinananceiroModule/getParcelamentoPorId',
        this.$route.params.id
      )
      const itensOrdenado = data.itens.sort(
        (firstItem, secondItem) =>
          firstItem.numeroParcela - secondItem.numeroParcela
      )
      this.form = {
        id: data.id,
        numero: data.numero,
        descricao: data.descricao,
        valor: data.valor,
        valorTotal: data.valorTotal,
        qtoParcelas: data.qtoParcelas,
        tipo: data.tipo.id,
        registro: data.registro,
        taxa: data.taxa.id,
        itens: itensOrdenado,
        dataLancamento: data.dataLancamento,
        conta: data.conta
      }
    }
    this.simulou = false
  },
  methods: {
    editParcela(data) {
      this.$router.push({
        path: `/modules/financial/recebimento/editrecebimento/${data.pagamentoRecebemento}`
      })
    },
    aceite(parcela) {
      this.showFormEmprestimo = true
      this.simulou = false
      this.carregarDados()
    },
    cancelar() {
      this.form = {
        id: null,
        numero: null,
        descricao: null,
        valor: null,
        valorTotal: null,
        qtoParcelas: null,
        tipo: null,
        registro: null,
        taxa: null,
        dataLancamento: null,
        conta: null
      }
      this.$emit('cancelar')
      if (this.$route.params.id) {
        this.$router.push({ name: 'emprestimo' })
      }
    },
    async carregarDados() {
      this.loading = true
      this.tipoFiltradoPorEmprestimos = []
      const listaTiposParcelamentos = await this.$store.dispatch(
        'parcelamentoFinananceiroModule/getParametrosParcelamento'
      )
      await this.$store.dispatch('combosModule/loadComboTipoPagamento')
      await this.$store.dispatch('combosModule/loadComboTaxas')
      await this.$store.dispatch('combosModule/loadComboContas')
      this.listaCodigosParametroEmprestimos =
        listaTiposParcelamentos.data.valor.split(',')
      this.comboTipoPagamento.forEach((tipo) => {
        this.listaCodigosParametroEmprestimos.forEach((codigo) => {
          if (parseInt(codigo) === parseInt(tipo.id)) {
            this.tipoFiltradoPorEmprestimos.push(tipo)
          }
        })
      })
      this.loading = false
    },
    prepareForSave(data) {
      const objectSave = JSON.parse(JSON.stringify(data))
      const obj = {
        id: objectSave.id,
        numero: objectSave.numero,
        descricao: objectSave.descricao,
        valor: objectSave.valor,
        qtoParcelas: objectSave.qtoParcelas,
        tipo: {
          id: objectSave.tipo
        },
        registro: objectSave.registro,
        taxa: {
          id: objectSave.taxa
        },
        conta: objectSave.conta
          ? {
              id: objectSave.conta
            }
          : null,
        dataLancamento: objectSave.dataLancamento
          ? objectSave.dataLancamento.split('T')[0]
          : ''
      }
      return obj
    },
    async save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          const objectSave = this.prepareForSave(this.form)
          this.$store
            .dispatch('parcelamentoFinananceiroModule/insert', objectSave)
            .then((response) => {
              if (response.response) {
                this.MensagemError(
                  `Falha ao Inserir o campo ${response.response.data.errors[0].fieldName}`,
                  `${response.response.data.errors[0].message}`
                )
                this.showForm = true
                return
              }
              if (response.error) {
                this.MensagemError(
                  `Falha ao Inserir  ${response.error}`,
                  `${response.message}`
                )
                return
              }
              this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
              this.cancelar()
            })
        }
      })
    },
    calcularParcelasSimulacao() {
      this.resultadoSimulacao.forEach((parcela) => {
        parcela.valor = this.form.valor / parcela.qtd
      })
    },
    simular() {
      if (!this.form.cpf) {
        const msg = this.cpfDigitado
          ? `CPF ${this.cpfDigitado} não encontrado`
          : 'O campo CPF é obrigatorio.'
        this.MensagemError(msg)
        return
      }
      this.simulou = true
      this.calcularParcelasSimulacao()
    },
    onSearch(search, loading) {
      loading(true)
      this.search(loading, search, this)
    },
    search: _.debounce(async (loading, search, vm) => {
      const registrosEncontrados = await vm.$store.dispatch(
        'pagamentoRecebimentoFinancialModule/searchRegistros',
        search
      )
      if (registrosEncontrados.response) {
        vm.MensagemError(
          'Falha na busca',
          `${registrosEncontrados.response.data.message}`
        )
        return
      }

      if (registrosEncontrados.data.content.length === 0) {
        vm.MensagemError('Zero registros', 'Nenhum registro encontrado')
        return
      }
      vm.comboRegistros = registrosEncontrados.data.content
      loading(false)
    }, 350)
  },
  data() {
    return {
      disabledSearch: false,
      comboRegistros: [],
      tipoFiltradoPorEmprestimos: [],
      listaCodigosParametroEmprestimos: [],
      required,
      showFormEmprestimo: false,
      listaSimulacaoShow: false,
      isValid: 'is-invalid',
      cpfDigitado: '',
      form: {
        id: '',
        numero: '',
        descricao: '',
        cpf: '',
        nome: '',
        valor: null,
        qtoParcelas: null,
        tipo: null,
        registro: null,
        taxa: null,
        conta: null
      },
      loading: true,
      simulou: false,
      objectFormInsert: {
        titleForm: 'Edição de Emprestimo'
      },
      options: {
        number: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.'
        }
      },
      columns: [
        {
          label: 'Némero da Parcela',
          field: 'numeroParcela',
          sortable: false
        },
        {
          label: 'Empréstimo',
          field: 'emprestimo',
          formatFn: this.formatCurrencyBrazil,
          sortable: false
        },
        {
          label: 'Prestação',
          field: 'prestacao',
          formatFn: this.formatCurrencyBrazil,
          sortable: false
        },
        {
          label: 'Juros',
          field: 'juros',
          sortable: false
        },
        {
          label: 'Amortização',
          field: 'amortizacao',
          formatFn: this.formatCurrencyBrazil,
          sortable: false
        },
        {
          label: 'Situação',
          field: 'situacao',
          sortable: false
        },
        {
          label: this.$t('Action'),
          field: 'action'
        }
      ],
      pageConfig: {
        page: 1,
        size: 10,
        sort: 'nome',
        order: 'asc'
      },
      pageLength: 10,
      dir: false,
      resultadoSimulacao: [],
      forEmprestimo: {
        numero: '',
        descricao: '',
        dataLancamento: '',
        primeiraVencimento: '',
        valorTotal: '',
        qtoParcelas: 0,
        registro: null,
        desconto: 0.0,
        tipo: null,
        taxa: null,
        conta: null
      }
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
